/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import { css } from "@emotion/core"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import SubFooter from "../components/SubFooter"
import Sidebar from "../components/Sidebar"
import TopBar from "../components/TopBar"
import { mediaQueries, fonts } from "../styles"

export default ({ data }) => {
  return (
    <Layout title="EDUCATION">
      <Sidebar title="EDUCATION" />
      {/* Main Content */}
      <div
        css={css`
          margin-left: 40vw;
          min-height: 100vh;
          display: grid;
          grid-template-rows: auto 1fr auto;
          ${mediaQueries.phoneLarge} {
            display: block;
            margin-left: 0;
          }
        `}
      >
        <TopBar />
        <div
          css={css`
            padding: 3rem 8rem;
            li {
              font-size: 1.2rem;
              font-weight: 400;
            }
            ${mediaQueries.phoneLarge} {
              padding: 40px 60px;
              li {
                margin-bottom: 0.4rem;
                font-size: 1rem;
                color: gray;
              }
            }
            h2,
            h3,
            h4 {
              font-family: ${fonts.sans};
            }
            h3 {
              font-size: 1.1rem;
            }
          `}
        >
          <h2>合格成绩</h2>
          <h3>东京艺术大学(共计 6 人)</h3>
          <ul>
            <li>GAP 专攻 吴同学(修士) </li>
            <li>先端专攻 吴同学(修士</li>
            <li>玻璃专攻 袁同学(修士) </li>
            <li>版画专攻 王同学(修士) </li>
            <li>动画专攻 李同学(修士) </li>
            <li>先端专攻 吴同学(研究生)</li>
          </ul>
          <h3>多摩美术大学(共计 19 人)</h3>
          <ul>
            <li>情报设计专攻 范同学(修士) </li>
            <li>油画专攻 韩同学(修士) </li>
            <li>版画专攻 洪同学(修士) </li>
            <li>油画专攻 黄同学(修士)</li>
            <li>雕塑专攻 姜同学(研究生) </li>
            <li>日本画专攻 林同学(研究生)</li>
            <li>平面插画专攻 刘同学(研究生) </li>
            <li>版画专攻 庞老师(博士) </li>
            <li>平面动画专攻 王同学(修士) </li>
            <li>版画专攻 王同学(修士) </li>
            <li>平面专攻 许同学(研究生) </li>
            <li>玻璃专攻 袁同学(修士)</li>
            <li>平面插画专攻 宗同学(修士) </li>
            <li>陶瓷专攻 邹同学(研究生) </li>
            <li>情报专攻 张同学(研究生) </li>
            <li>平面专攻 董同学(学部) </li>
            <li>版画专攻 彭同学(学部) </li>
            <li>雕塑专攻 魏同学(学部) </li>
            <li>油画专攻 胡同学(修士)</li>
          </ul>
          <h3>藏野美术大学(共计 12 人)</h3>
          <ul>
            <li>油画专攻 韩同学(修士)</li>
            <li>版画专攻 洪同学(研究生) </li>
            <li>油画专攻 黄同学(研究生) </li>
            <li>雕塑专攻 姜同学(修士) </li>
            <li>动画专攻 李同学(修士) </li>
            <li>动画专攻 吴同学(修士)</li>
            <li>版画专攻 周同学(修士) </li>
            <li>平面专攻 王同学(修士) </li>
            <li>工艺工业专攻 刘同学(学部) </li>
            <li>油画专攻 缪同学(学部) </li>
            <li>雕塑专攻 魏同学(学部) </li>
            <li>建筑设计专攻 胡同学(学部)</li>
          </ul>
          <h3>女子美术大学(共计 14 人)</h3>
          <ul>
            <li>治愈专攻 王同学(修士) </li>
            <li>油画专攻 黄同学(修士) </li>
            <li>雕塑专攻 姜同学(修士) </li>
            <li>雕塑专攻 张同学(修士) </li>
            <li>多媒体专攻 张同学(修士) </li>
            <li>多媒体专攻 张同学(修士) </li>
            <li>版画专攻 王同学(修士)</li>
            <li>版画专攻 彭同学(学部) </li>
            <li>洋画专攻 张同学(学部) </li>
            <li>插画专攻 耿同学(学部) </li>
            <li>洋画专攻 陆同学(修士) </li>
            <li>设计短大 吴同学(短大) </li>
            <li>造型专攻 吴同学(专攻) </li>
            <li>设计短大 谭同学(短大)</li>
          </ul>
          <h3>东京造型大学(共计 2 人)</h3>
          <ul>
            <li>室内设计专攻 杨同学(学部) </li>
            <li>油画专攻 胡同学(修士)</li>
          </ul>
          <h3>明治大学(共计 1 人)</h3>
          <ul>
            <li>艺术学专攻 金同学(研究生)</li>
          </ul>
          <h3>筑波大学(共计 1 人)</h3>
          <ul>
            <li>工业设计专攻 张同学(修士)</li>
          </ul>
          <h3>日本写真艺术专门(共计 1 人)</h3>
          <ul>
            <li>摄影专攻 钱同学(学部)</li>
          </ul>
          <h3>日本大学艺术学部(共计 1 人)</h3>
          <ul>
            <li>雕塑专攻 张同学(修士)</li>
          </ul>
          <h3>文化学院大学(共计 2 人)</h3>
          <ul>
            <li>建筑专攻 胡同学(学部) </li>
            <li>服装设计专攻 王同学(学部)</li>
          </ul>
          <h3>东京工艺大学(共计 2 人)</h3>
          <ul>
            <li> 动漫专攻 陈同学(学部) </li>
            <li> 工业设计专攻 胡同学(学部)</li>
          </ul>
          <h3>京都精华大学(共计 2 人)</h3>
          <ul>
            <li> 插画专攻 陈同学(学部) </li>
            <li> 新世代漫画专攻 凤同学(学部)</li>
          </ul>
          <h3>日本大学艺术学部(共计 2 人)</h3>
          <ul>
            <li> 室内设计专攻 杨同学(学部) </li>
            <li> 油画专攻 胡同学(修士)</li>
          </ul>
          <h3>大阪艺术大学(共计 3 人)</h3>
          <ul>
            <li>角色设计专攻 於同学(学部) </li>
            <li>平面设计专攻 吕同学(修士) </li>
            <li>室内设计专攻 杜同学(修士)</li>
          </ul>
          <h3>京都造型大学(共计 3 人)</h3>
          <ul>
            <li> 动画专攻 丁同学(修士) </li>
            <li> 版画专攻 彭同学(学部) </li>
            <li> 油画专攻 李同学(学部)</li>
          </ul>
          <h3>宝冢美术大学(共计 3 人)</h3>
          <ul>
            <li> 情报设计专攻 樊同学(学部) </li>
            <li> 绘本专攻 王同学(修士) </li>
            <li> 平面设计专攻 许同学(修士)</li>
          </ul>
          <h2>课程价目表</h2>
          <h3>专攻分野</h3>
          <h4>艺术类</h4>
          <ul>
            <li>視覚伝達デザイン </li>
            <li>情報デザイン </li>
            <li>基礎デザイン </li>
            <li>統合デザイン</li>
            <li>空間演出デザイン </li>
            <li>環境デザイン </li>
            <li>建築デザイン </li>
            <li>工業デザイン </li>
            <li>交互デザイン</li>
            <li>漫画デザイン </li>
            <li>ファッショデザイン </li>
            <li>テキスタイルデザイン </li>
            <li>絵本デザイン</li>
            <li>キャラクターデザイン </li>
            <li>アートプロデュース </li>
            <li>インテリアデザイン</li>
            <li>ソーシャルデザイン </li>
            <li>メディア デザイン </li>
            <li>アニメーション </li>
            <li>ゲームデザイン</li>
            <li>グローバルアート </li>
            <li>クリエイティブイノベーション学科 </li>
            <li>映画専攻 </li>
            <li>映像専攻</li>
            <li>写真専攻 </li>
            <li>イラストレーション専攻</li>
            <li>先端芸術表現</li>
            <li>舞台美術専攻</li>
            <li>艺术学専攻</li>
            <li>日本画専攻</li>
            <li>彫刻専攻</li>
            <li>陶艺専攻</li>
            <li>漆艺専攻</li>
            <li>油画専攻</li>
            <li>版画専攻</li>
            <li>工芸工業</li>
            <li>造形構造</li>
            <li>保存修復</li>
            <li>美術教育</li>
          </ul>
          <h4>音乐类</h4>
          <ul>
            <li>作曲·録音</li>
          </ul>
          <h3>学部课程分类</h3>
          <ul>
            <li>6 个月套餐 价格 580,000 円</li>
            <li>通年安心月套餐 价格 950,000 円</li>
            <li>综合安心套餐 价格 1,050,000 円</li>
            <li>单月课程 价格 140,000 円</li>
            <li>学部日语口语 价格 120,000 円</li>
          </ul>
          <h3>大学院课程分类</h3>
          <h4>单科课程</h4>
          <ul>
            <li>一对一 30 小时 价格 360,000 円</li>
            <li>一对一 60 小时 价格 720,000 円</li>
            <li>一对一 60 小时以上 价格 11,400 円 / 课时</li>
            <li>大学院口语课程 价格 100,000 円</li>
            <li>大学院日语 JLPT N1N2 对策课程 价格 100,000 円</li>
          </ul>
          <h4>套餐课程</h4>
          <ul>
            <li>
              30 小时 VIP 套餐 30 小时 VIP+ 美术口语 + 助教答疑 价格 500,000 円
            </li>
            <li>
              60 小时 VIP 套餐 60 小时 VIP+ 美术口语 + 助教答疑 价格 850,000 円
            </li>
          </ul>
        </div>
        <SubFooter />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
